var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-car-reco" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c("label", [_vm._v("二维码记录")]),
        _c(
          "div",
          [
            !_vm.type
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.search },
                  },
                  [_vm._v("高级搜索")]
                )
              : _vm._e(),
            _c(
              "el-radio-group",
              {
                staticClass: "label",
                attrs: { size: "small" },
                on: { change: _vm.typeChange },
                model: {
                  value: _vm.type,
                  callback: function ($$v) {
                    _vm.type = $$v
                  },
                  expression: "type",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: 1 } }, [
                  _vm._v("实时"),
                ]),
                _c("el-radio-button", { attrs: { label: 0 } }, [
                  _vm._v("分页"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "280" },
              }),
              _c("el-table-column", {
                attrs: { prop: "qrcode", label: "二维码", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("vue-qr", {
                          attrs: {
                            qid: "qrid",
                            text: _vm.transform(scope.row),
                            size: _vm.qrcode.size,
                            bgSrc: _vm.qrcode.bgSrc,
                            logoSrc: _vm.qrcode.logoSrc,
                            callback: _vm.qrcode.callBack,
                            logoScale: _vm.qrcode.logoScale,
                            colorDark: _vm.qrcode.colorDark,
                            colorLight: _vm.qrcode.colorLight,
                            backgroundColor: _vm.qrcode.backgroundColor,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "infoList", label: "二维码信息" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.infoList, function (item, index) {
                        return _c("p", { key: index }, [
                          _vm._v(" " + _vm._s(item.key + ":" + item.val) + " "),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtCreated", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "qrLibName", label: "二维码库名称" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.type
        ? _c("common-pagination", {
            on: { curPageChange: _vm.handleCurPageChange },
            model: {
              value: _vm.pageOptions,
              callback: function ($$v) {
                _vm.pageOptions = $$v
              },
              expression: "pageOptions",
            },
          })
        : _vm._e(),
      _c("car-reco-search", {
        on: { handleSearch: _vm.handleSearch },
        model: {
          value: _vm.searchDialogVisible,
          callback: function ($$v) {
            _vm.searchDialogVisible = $$v
          },
          expression: "searchDialogVisible",
        },
      }),
      _c("common-delete", {
        on: { handleDelete: _vm.handleDelete },
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }