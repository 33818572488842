var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form",
      attrs: {
        title: "高级搜索",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("ul", [
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("创建时间"),
            ]),
            _c("date-range", {
              model: {
                value: _vm.dateRange,
                callback: function ($$v) {
                  _vm.dateRange = $$v
                },
                expression: "dateRange",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("创建人"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入创建人", size: "small" },
              model: {
                value: _vm.createdName,
                callback: function ($$v) {
                  _vm.createdName = $$v
                },
                expression: "createdName",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("警示类型"),
            ]),
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.status,
                  callback: function ($$v) {
                    _vm.status = $$v
                  },
                  expression: "status",
                },
              },
              [
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("正常")]),
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("警示")]),
                _c("el-radio", { attrs: { label: -1 } }, [_vm._v("不在库中")]),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("VIP")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }