<template>
  <div class="js-common-wrap js-car-reco">
    <div class="js-common-head">
      <label>二维码记录</label>
      <div>
        <el-button v-if="!type" type="primary" size="small" @click="search"
          >高级搜索</el-button
        >
        <el-radio-group
          class="label"
          v-model="type"
          size="small"
          @change="typeChange"
        >
          <el-radio-button :label="1">实时</el-radio-button>
          <el-radio-button :label="0">分页</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="js-common-content">
      <el-table
        class="js-table"
        :data="tableData"
        height="100%"
        empty-text="暂无数据"
      >
        <el-table-column prop="id" label="ID" width="280"></el-table-column>
        <el-table-column prop="qrcode" label="二维码" width="200">
          <template slot-scope="scope">
            <vue-qr
              qid="qrid"
              :text="transform(scope.row)"
              :size="qrcode.size"
              :bgSrc="qrcode.bgSrc"
              :logoSrc="qrcode.logoSrc"
              :callback="qrcode.callBack"
              :logoScale="qrcode.logoScale"
              :colorDark="qrcode.colorDark"
              :colorLight="qrcode.colorLight"
              :backgroundColor="qrcode.backgroundColor"
            ></vue-qr>
          </template>
        </el-table-column>
        <el-table-column prop="infoList" label="二维码信息">
          <template slot-scope="scope">
            <p v-for="(item, index) in scope.row.infoList" :key="index">
              {{ item.key + ":" + item.val }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="gmtCreated" label="创建时间"></el-table-column>
        <el-table-column
          prop="qrLibName"
          label="二维码库名称"
        ></el-table-column>
      </el-table>
    </div>
    <common-pagination
      v-if="!type"
      v-model="pageOptions"
      @curPageChange="handleCurPageChange"
    ></common-pagination>
    <car-reco-search
      v-model="searchDialogVisible"
      @handleSearch="handleSearch"
    ></car-reco-search>
    <common-delete
      v-model="deleteDialogVisible"
      @handleDelete="handleDelete"
    ></common-delete>
  </div>
</template>
<script>
import CommonPagination from "@/components/CommonPagination";
import CarRecoSearch from "@/components/CarRecoSearch";
import CommonDelete from "@/components/CommonDelete";
import { mapGetters } from "vuex";
import { RTMClient, RTMChannelService } from "@/assets/js/RTMClient";
import VueQr from "vue-qr";

export default {
  name: "",
  components: {
    CommonPagination,
    CarRecoSearch,
    CommonDelete,
    VueQr,
  },
  data() {
    return {
      qrcode: {
        href: "",
        text: "https://github.com/MuGuiLin", // 二维码内容
        size: 120, // 二维码宽高尺寸, 长宽一致, 包含外边距
        bgSrc: "", // 嵌入背景图地址，
        logoSrc: "", // 嵌入至二维码中心的 LOGO 地址
        margin: 0, // 二维码图像的外边距, 默认20px
        colorDark: "#000", // 实点的颜色
        colorLight: "#fff", // 空白区的颜色
        backgroundColor: "#EEE", // 背景色
        logoScale: 0.15, // 中间图的尺寸，不要设太大，太大会导致扫码失败的 默认0.2
        dotScale: 0.35, // 数据区域点缩小比例,默认为0.35
      },
      type: 1,
      tableData: [],
      searchDialogVisible: false,
      deleteDialogVisible: false,
      options: {
        startDate: "",
        endDate: "",
        createdName: "",
        status: "",
      },
      pageOptions: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      currentItem: "",
      rtmCommonClient: "",
      rtmCommonChannel: "",
      timerChannel: "",
      realInfo: "",
      channelMsg: {
        url: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  mounted() {
    this.getList();
    this.rtmClient();
  },
  beforeDestroy() {
    clearInterval(this.timerChannel);
  },
  methods: {
    rtmClient() {
      this.initialRoom();
    },
    initialRoom() {
      // rtmCommonChannel为异步第二步，它初始化完成后，2个对象就都有了，再执行事件绑定
      if (window?.GLOBAL?.state === "ready") {
        this.CommonRtcClient = window.GLOBAL.CommonRtcClient;
        this.CommonRongIMLib = window.GLOBAL.CommonRongIMLib;
        this.CommonRongCaller = window.GLOBAL.Caller;
        this.Events = window.GLOBAL.Events;

        this.getMessage();
      } else {
        setTimeout(this.initialRoom, 2000);
      }
    },
    getMessage() {
      const MESSAGES = this.CommonRongIMLib.Events.MESSAGES;

      this.CommonRongIMLib.addEventListener(MESSAGES, (event) => {
        // 处理消息队列
        event.messages.map((message) => {
          console.log(message);
          const memberId = message?.senderUserId;
          // 文本消息
          if (message.messageType === "RC:TxtMsg") {
            let msg = JSON.parse(message.content.content);
            let url = "";
            console.log(
              `-------------------------收到的${memberId}发送的消息${msg}---------`
            );
            // 判断是否是二维码
            if (msg.mode == "qr") {
              url = "api/csp/qr/v1/qrRecord/newest/";
              this.getRealInfo(url, msg.mark);
            }
          }
        });
      });
    },
    getMsg() {
      this.rtmCommonChannel.on("MemberJoined", (memberId) => {
        // 你的代码：用户已加入频道。
        console.log("memberId", memberId);
      });
      this.rtmCommonChannel.on("ChannelMessage", (message, memberId) => {
        console.log("message", message);
        console.log("memberId", memberId);
        // 获取token,channel
        if (message.messageType === "TEXT") {
          if (message.text) {
            let msg = JSON.parse(message.text);
            let url = "";
            console.log(
              "---------------------------------------收到的msg--------------------------------------------"
            );
            console.log(msg);
            // 判断是否是车牌识别
            if (msg.mode == "qr") {
              url = "api/csp/qr/v1/qrRecord/newest/";
              this.getRealInfo(url, msg.mark);
            }
          }
        }
      });
    },
    // 获取实时推送数据
    getRealInfo(url, id) {
      let tmp = this.tableData;
      this.$axios({
        method: "get",
        url: `${url}${id}?type=1`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.realInfo = data.data;
          this.tableData = this.realInfo.concat(tmp).slice(0, 20);
          console.log(this.tableData);
          this.$forceUpdate();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    getList() {
      let url = "",
        params = "";
      if (this.type) {
        url = "api/csp/qr/v1/qrRecord/newest/0?type=1";
        params = "";
      } else {
        url = "api/csp/qr/v1/qrRecord/page";
        params = {
          startDate: this.options.startDate,
          endDate: this.options.endDate,
          createdName: this.options.createdName,
          status: this.options.status,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage,
        };
      }
      this.$axios({
        method: "get",
        url: url,
        params: params,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          if (this.type) {
            this.tableData = data.data;
          } else {
            this.tableData = data.data.rows;
            this.pageOptions.total = data.data.total;
          }
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 分页
    handleCurPageChange() {
      this.getList();
    },
    typeChange() {
      this.getList();
      if (this.type) {
        this.rtmClient();
      }
    },
    deleteList(item) {
      this.currentItem = item;
      this.deleteDialogVisible = true;
    },
    handleDelete() {
      this.$axios({
        method: "delete",
        url: `api/csp/lpr/v1/lprRecord/${this.currentItem.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.deleteDialogVisible = false;
          this.$message({
            type: "success",
            message: "删除成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    search() {
      this.searchDialogVisible = true;
    },
    handleSearch(params) {
      this.pageOptions.page = 1;
      this.options.startDate = params.startDate;
      this.options.endDate = params.endDate;
      this.options.createdName = params.createdName;
      this.options.status = params.status;
      this.getList();
      this.searchDialogVisible = false;
    },
    handleRowClick(row) {
      this.$router.push({
        path: `/aiRecognition/car/carInfos`,
        query: {
          id: row.id,
        },
      });
    },
    transform(row) {
      const key = this.$store.state.userInfo.tenantCode;
      return key + ":" + row.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/recognition.scss";
</style>
