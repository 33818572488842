<template>
  <el-dialog class="js-dialog-form" title="高级搜索" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label">创建时间</li> -->
      <li>
        <div class="label common-tip"><i>*</i>创建时间</div>
        <date-range v-model="dateRange"></date-range>
      </li>
      <!-- <li class="label">创建人</li> -->
      <li>
        <div class="label common-tip"><i>*</i>创建人</div>
        <el-input v-model="createdName" placeholder="请输入创建人" size="small"></el-input>
      </li>
      <!-- <li class="label">警示类型</li> -->
      <li>
        <div class="label common-tip"><i>*</i>警示类型</div>
        <el-radio-group v-model="status" size="small">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="1">警示</el-radio>
          <el-radio :label="-1">不在库中</el-radio>
          <el-radio :label="2">VIP</el-radio>
        </el-radio-group>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DateRange from '@/components/DateRange'
export default {
  name: '',
  components: {
    DateRange
  },
  props: ['value'],
  data() {
    return {
      dateRange: ['',''],
      createdName: '',
      status: ''
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {
  },
  methods: {
    handleCancel(){
      this.dateRange = ['','']
      this.createdName = ''
      this.status = ''
      this.dialogVisible = false
    },
    handleConfirm(){
      let params = {
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        createdName: this.createdName,
        status: this.status
      }
      this.$emit('handleSearch',params)
    }
  },
};
</script>

<style lang="scss" scoped>

</style>